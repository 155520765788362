<!--
 * @Descripttion: 套餐管理
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-19 17:43:10
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-05-13 14:42:25
-->
<template>
    <div class="serviceManagement">
        <div class="sm-title">
            <div class="mt-title">预约演示</div>
        </div>
        <div class="sm-content">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column align="center" prop="name" label="预约名称"></el-table-column>
                <el-table-column align="center" prop="phone" label="预约电话"></el-table-column>
                <el-table-column align="center" prop="time" label="预约时间"></el-table-column>
                
            </el-table>
        </div>
    </div>
</template>

<script>
import api from "@/utils/common.js"
import pageMixin from '@/internal/pageMixin'
export default {
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'subscribeList',
            tableData: [],
        }
    },
    mounted () {
        this.getFeedbackList()
    },
    methods: {
        // 获取用户预约
        getFeedbackList(){
            let params = {
                page_num: 1,
                page_size: 200,
            }
            api.post('/service/system/get_user_reservation_list', params, (res) =>  {
                this.tableData = res.data.list
            });
        },
    },
}
</script>

<style lang='scss'>
.serviceManagement{
    .sm-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 20px 40px;
        .mt-title{
            font-size: 24px;
            color: #101010;
        }
        .mt-add{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .add{
                width: 130px;
                height: 36px;
                border-radius: 6px;
                border: 1px solid #0068ff;
                font-size: 14px;
                color: #0068ff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }
    .sm-content{
        margin: 20px;
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        .code{
            text-align: center;
            width: 150px;
            padding: 5px 20px;
            border: 1px solid #ddd;
            background: #e6effd;
            border-radius: 9px;
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn{
                padding: 0 15px;
                cursor: pointer;
                font-size: 14px;
                color: #0068ff;
            }
        }
        .img-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            .img{
                width: 50px;
                height: 50px;
                margin-right: 10px;
            }
        }
    }
}
</style>
